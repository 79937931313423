import { OPENED } from "commom/consts/evaluations/const-status";
import { useEvaluationContext } from "commom/contexts/useEvaluationContext";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, ListGroup, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import style from "./list-projects-evaluation.module.css";
import { SiGooglemaps } from "react-icons/si";
import OffcanvasMap from "components/offcanvas/offcanvas-map";
import coords from "commom/consts/stands/2023/const-stand-coordinates";


const ProjectEvaluationItem = ({ project, onAbstractClick, projectLink, onMapClick, projectUuid }) => {
    const { hasProjectEvaluated, calcProjectGrades, sheets } = useEvaluationContext();

    const hasCompletedEvaluation = hasProjectEvaluated(project.code);

    const sheet = sheets.find((sheet) => sheet.uuid === projectUuid);

    const hasProjectBox = Boolean(project.box)

    const projectDisplay = hasProjectBox? `Estande ${project.box}` : project.code

    const getButtonText = () => {
        if (sheet?.status !== OPENED) {
            return "Consultar";
        }

        if (!hasCompletedEvaluation) {
            return "Avaliar";
        } else {
            return "Editar";
        }
    }

    return (
        <ListGroup.Item key={project.code} className={`${hasCompletedEvaluation ? "bg-success bg-opacity-10 border-success" : ""}`}>
            {
                !hasCompletedEvaluation ?
                    <div className="d-flex justify-content-between">
                        <p className="m-0 fw-bold">
                            {projectDisplay}
                        </p>

                        {/* {hasProjectBox &&
                         <div>
                            <button
                                aria-label="Abrir mapa dos estandes"
                                title="Abrir mapa dos estandes"
                                className="border-0 bg-transparent"
                                onClick={onMapClick}>
                                <SiGooglemaps />
                            </button>
                         </div>
                        } */}
                    </div>
                    :
                    <div className="d-flex justify-content-between">
                        <p className="m-0 fw-bold">
                            {projectDisplay}
                        </p>
                        <div className="d-flex flex-column align-items-end">
                            <p className="text-green m-0">
                                <b>Avaliação realizada</b>
                            </p>

                            <p>
                                <b> Nota total: {calcProjectGrades(project.code)}%</b>
                            </p>
                        </div>
                    </div>
            }

            <p className="text-truncate">
                {project.title}
            </p>
            <ButtonGroup className="d-flex" aria-label="Visualizar resumo ou editar avaliação">
                <Button
                    variant="lilac"
                    style={{ flex: 1 }}
                    onClick={() => onAbstractClick(project)}
                >
                    Ver resumo
                </Button>
                <Button
                    style={{ flex: 1 }}
                    as={Link}
                    variant="purple"
                    to={projectLink}
                >
                    {getButtonText()}
                </Button>
            </ButtonGroup>

        </ListGroup.Item>
    )
}

/**
 * 
 * @param {{projects:Array<{code:string, abstract:string, title:string}>, uuidStr:string} {{projects,uuidStr}}}
 * @returns 
 */
const ListProjectsEvaluation = ({ projects = [], uuidStr }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedProject, setSelectedProject] = useState(null);
    const [showAbstract, setShowAbstract] = useState(false);
    const [offcanvasMapProps, setOffcanvasMapProps] = useState({
        showMap: false,
        standPositionX: 0,
        standPositionY: 0,
        title: ''
    });

    const onCloseOffcanvas = () => {
        setShowAbstract(false);
        setSelectedProject(null);

        navigate(location.pathname, { replace: true });
    }

    const onMapHide = () => {
        setOffcanvasMapProps({
            showMap: false,
            standPositionX: 0,
            standPositionY: 0,
            title: ''
        });

        navigate(location.pathname, { replace: true });
    }

    const onMapClick = (project) => {
        const coord = coords.find((coord) => coord.estande === project.box);

        setOffcanvasMapProps({
            showMap: true,
            standPositionX: coord?.X_percentage,
            standPositionY: coord?.Y_percentage,
            title: `Estande N.º: ${project.box}`
        });

        // Append project code in the url
        navigate(`${location.pathname}#${project.code}`);
    }

    useEffect(() => {
        //Check if the project code is in url; 
        if (!location.hash) {
            //Close all modals/offcanvas.
            setShowAbstract(false);

            setOffcanvasMapProps({
                showMap: false,
                standPositionX: 0,
                standPositionY: 0,
                title: ''
            });
        }

    }, [location]);

    const onAbstractClick = project => {
        setSelectedProject(project);
        setShowAbstract(true);

        // Append project code in the url
        navigate(`/avaliacao/${uuidStr}/menu#${project.code}`);
    }

    return (
        <>
            <ListGroup className={style.listProjects} role="list">
                {projects.map(project => (
                    <ProjectEvaluationItem
                        key={project.code}
                        project={project}
                        projectUuid={uuidStr}
                        projectLink={`/avaliacao/${uuidStr}/avaliar/${project.code}`}
                        onAbstractClick={() => onAbstractClick(project)}
                        onMapClick={() => onMapClick(project)}
                    />
                ))}
                {projects?.length === 0 &&
                    <ListGroup>
                        Nenhum projeto disponível para avaliação.
                    </ListGroup>
                }
            </ListGroup>

            <Offcanvas show={showAbstract} onHide={onCloseOffcanvas}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        {selectedProject?.box? selectedProject && `Estande ${selectedProject?.box}` : selectedProject?.code}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h1 className="h4 mb-3">
                        {selectedProject?.title}
                    </h1>
                    <p className="text-justify">
                        {selectedProject?.abstract}
                    </p>
                </Offcanvas.Body>
            </Offcanvas>

            <OffcanvasMap
                showMap={offcanvasMapProps.showMap}
                onHide={onMapHide}
                x={offcanvasMapProps.standPositionX}
                y={offcanvasMapProps.standPositionY}
                title={offcanvasMapProps.title}
            />
        </>
    )
}

export {
    ListProjectsEvaluation,
    ProjectEvaluationItem
}